import React from 'react'
import styled from 'styled-components'

import { Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import { Link } from '../../components/Link'

const ChatWrapper = styled.div`
  & > button {
    bottom: 0;
    position: relative;
    right: 0;
  }
`

const Page = () => (
  <Content heading="FeedbackButton">
    <Section>
      <Paragraph>
        Mahdollistaa Palaute-painikkeen näyttämisen Verkkopalvelussa ennen{' '}
        <Link page="Chat" />
        -komponentin lataamista.
      </Paragraph>
      <Playground
        format="html"
        WrapperComponent={ChatWrapper}
      >
        <ButtonHTMLExample
          className="feedback-button"
          color="info"
          iconLeft={<IconHTMLExample name="SpeechBubbleIcon" />}
          shape="square"
          size="sm"
        >
          Palaute
        </ButtonHTMLExample>
      </Playground>
    </Section>
  </Content>
)

export default Page
